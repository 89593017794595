.message-content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.message-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background:
      radial-gradient(circle at bottom left, #FFFFFF, transparent 20%),
      radial-gradient(circle at top right, #FFF965, transparent 20%),
      linear-gradient(var(--color-main), var(--color-main));
  background-blend-mode: screen;


  padding: 24px 0 14px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.message-header .logo {
  width: 50px;
}

.header-title {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.custom-modal-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  overflow: auto;
}

.custom-modal {
  width: 80%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);;
  border: 1px solid var(--color-main-background);
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.custom-modal .ant-modal {
  top: 50px;
}

.message-content-footer {
  padding: 0 24px 9px 24px;
}
.message-content-footer p {
  margin: 0;
}

.custom-modal .message-content {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  max-height: 50vh;

  background: var(--color-info-background);
  padding: 14px 24px 9px 24px;
  border-radius: 10px;
  overflow: auto;
}

.custom-modal .message-content audio,
.custom-modal .message-content video {
  width: 100%;
}
