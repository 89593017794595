.auth-component {
  min-height: 100vh;
  background: var(--color-info-border);
}

.auth-component .logo {
  text-align: center;
  margin: 20px 0 30px;
}

.auth-component .logo img {
  max-width: 60%;
}

.auth-component .block {
  background: #fff;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
}

.auth-component h1 {
  margin: 0;
  font-size: 28px;
}

.auth-component .subtitle {
  margin: 10px 0;
}

.auth-component .subtitle a {
  text-decoration: underline;
  font-weight: 600;
}

.auth-component button[type='submit'] {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 26px;
  padding: 8px 0;
  margin-top: 20px;
  height: auto;
}

.auth-component .ant-alert {
  margin: 20px 0;
  text-align: left;
}
