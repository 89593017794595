.subscription-checkout .product-info {
  background: var(--color-info-border);
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 20px;
}

.subscription-checkout .product-info .total {
  font-weight: 600;
}

.subscription-checkout .prev-sub {
  border: 2px solid var(--color-btn-background);
  padding: 20px;
  min-width: 200px;
}

.subscription-checkout .next-sub {
  border: 2px solid var(--color-main);
  padding: 20px;
  min-width: 200px;
}

.subscription-checkout .main-block {
  font-size: 18px;
  margin: 20px 0;
}
.subscription-checkout .main-block .subtitle {
  font-size: initial;
  color: var(--color-text-lightgray);

}
.subscription-checkout .main-block .price {
  font-weight: bold;
}

.subscription-checkout .sub-block {
  background: var(--color-btn-background);
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0;
}
.subscription-checkout .sub-block span {
  display: block;
  font-size: 14px;
}

.subscription-checkout .pay-button {
  margin-top: 20px;
}

.subscription-checkout .error-message {
  margin-bottom: 10px;
}
