.header {
  border-bottom: 1px solid var(--color-main-background);
}

.header .logo {
  height: 36px;
}

.header .right {
  justify-content: right;
  align-items: center;
  display: flex;
}

.header .header-public {
  margin: 15px 0;
}

.header .header-public .logo {
  height: 72px;
}

.header ul.menu {
  list-style: none;
  margin: 0 10px 0;
  padding: 0;
  display: flex;
}

.header ul.menu li {
  padding: 20px 8px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 500;
}

.header ul.menu li a.active {
  font-weight: bold;
}

.header .credits {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.header .credits .credits-name {
  margin-right: 15px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-main);
  cursor: pointer;
  text-align: center;
}

.header .credits .credits-count {
  cursor: pointer;
  background: var(--color-text-main);
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  color: var(--color-info-background);
  letter-spacing: -0.5px;
  white-space: nowrap;
}

.header .user-button {
  background: var(--color-main-background);
  border-radius: 20px;
  padding: 8px 12px 8px 8px;
  margin-left: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.header .user-button.ant-dropdown-open {
  background: var(--color-main);
  color: #fff;
}

.header .user-button .avatar {
  margin-right: 12px;
}

.header-mobile {
  margin: 10px 0;
}
.header-desktop {
  display: none;
}

@media (min-width: 992px) {
  .header-mobile {
    display: none;
  }
  .header-desktop {
    display: block;
  }
  .header .user-button {
    display: flex;
  }
  .header .credits {
    cursor: initial;
  }
}


