.create-message-component {
  flex: 1;
  margin: 30px 0;
}

.create-message-component-full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-message-component-title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #042a21;
  text-align: center;
}

.create-message-component-subtitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #042a21;
  text-align: center;
}

.create-message-component-footer {
  margin: 40px 0 20px;
}

.create-message-component .confirm-block {
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

.create-message-component .success-block {
  text-align: center;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.create-message-component .success-block .image {
  max-height: 200px;
}

.create-message-component .success-block .block-center {
  width: 100%;
}
