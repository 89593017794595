.public-header {
  border-bottom: 1px solid var(--color-main-background);
  padding: 16px 32px 14px 56px;
}

.public-header .logo-container {
  justify-content: center;
}

.public-header .logo-container .logo {
  width: 250px;
}

@media (max-width: 768px) {
  .public-header .logo-container .logo {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .public-header .logo-container .logo {
    width: 100px;
  }
}

.public-header .right {
  justify-content: right;
  align-items: center;
  display: flex;
}

.public-header ul.menu {
  list-style: none;
  margin: 0 10px 0;
  padding: 0;
  display: flex;
}

.public-header ul.menu li {
  padding: 20px 10px;
  text-transform: uppercase;
  white-space: nowrap;
}

.public-header .menu-text {
  color: var(--color-main);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .public-header .menu-text {
    font-size: 10px;
    line-height: 16px;
  }
}

.public-header ul.menu li a.active {
  font-weight: bold;
}

.public-header .primary-button {
  background: transparent;
  border: none;
  color: var(--color-main);
  padding: 3px 0;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 185.714% */
  text-transform: uppercase;
}

.public-header .primary-button.ant-dropdown-open {
  background: var(--color-main);
  color: #fff;
}

.public-header .sub-button {
  background: var(--color-main-background);
  border-radius: 31px;
  padding: 6px 10px;
  margin-left: 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.public-header .sub-button.ant-dropdown-open {
  background: var(--color-main);
  color: #fff;
}


@media (max-width: 768px) {
  .public-header .primary-button,
  .public-header .sub-button {
    font-size: 10px;
    line-height: 16px;
  }

  .public-header .sub-button {
    margin-left: 4px;
  }
}

.public-header .user-button {
  display: flex;
}
.public-header .user-button .name {
  margin-right: 12px;
  max-width: 150px;
}
.public-header .user-button .avatar {
  margin-right: 12px;
}
