.create-gift-component {
  padding-top: 20px;
}

.create-gift-component .main-title {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
  color: #042a21;
}

.create-gift-component .main-subtitle {
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0;
  color: #042a21;
}

.create-gift-component .progress {
  color: var(--color-text-lightgray);
  font-weight: 600;
}

.create-gift-component .progress .active {
  color: var(--color-main);
}

.create-gift-component .progress .title {
  font-size: 16px;
}

.create-gift-component .progress .bar {
  margin: 5px 0;
  width: 100%;
  background: var(--color-main-background);
  height: 10px;
  border-radius: 10px;
}

.create-gift-component .progress .active .bar {
  background: var(--color-main);
}

.create-gift-component .success-block {
  margin-top: 50px;
}

.create-gift-component .gift-button {
  margin-top: 10px;
  background: var(--color-main-background);
  color: var(--color-main);
  font-weight: 600;
  border: var(--color-main-background);
}

.create-gift-component .payment-plans {
  text-align: center;
  padding: 30px 0;
}

.create-gift-component .payment-plans .payment-plan-item {
  border: 1px solid #D9D9D9;
  padding: 20px;
  max-width: 350px;
  transition: all .2s ease-in;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.create-gift-component .payment-plans .payment-plan-item:hover {
  transform: translate(0, -4px);
  box-shadow: -1px 4px 32px 5px rgba(0, 0, 0, .2);
}

.create-gift-component .payment-plans .payment-plan-item .plan-block {
  height: 100%;
}

.create-gift-component .payment-plans .payment-plan-item .title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: var(--color-text-secondary);
}

.create-gift-component .payment-plans .payment-plan-item .price {
  font-weight: 900;
  font-size: 36px;
  margin: 10px 0;
}

.create-gift-component .payment-plans .payment-plan-item .price .variant {
  color: #848C90;
  font-size: 16px;
  font-weight: normal;
}

.create-gift-component .payment-plans .payment-plan-item .highlights {
  text-align: left;
}

.create-gift-component .payment-plans .payment-plan-item .highlights ul {
  list-style-type: none;
  padding-left: 5px;
  margin: 10px 0 30px;
}

.create-gift-component .payment-plans .payment-plan-item .highlights li {
  margin: 10px 0;
}

.create-gift-component .payment-plans .payment-plan-item .highlights ul li.disabled {
  color: var(--color-text-lightgray);
}

.create-gift-component .payment-plans .payment-plan-item .highlights ul li .tick-icon {
  color: var(--color-main);
}

.create-gift-component .payment-plans .payment-plan-item button {
  border-radius: 0;
  font-weight: 600;
}

.create-gift-component .image {
  height: 200px;
}

.create-gift-component .ant-form-item-label {
  font-weight: 500;
}

