.messages-component .message-title {
  display: flex;
  justify-content: space-between;
}

.messages-component .draft-warning {
  text-transform: initial;
  font-size: 14px;
}

.messages-component .set-admin {
  cursor: pointer;
}
