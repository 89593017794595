.public-footer {
  /*position: absolute;*/
  background-color: var(--color-main);
  width: 100%;
  margin-top: 26px;
  bottom: 0;
}

.public-footer .public-footer-container {
  display: flex;
  padding: 18px 24px 18px 32px;
}

.public-footer .logo-container {
  justify-content: start;
}

.public-footer .logo-container .logo {
  width: 61px;
  height: 66px;
  flex-shrink: 0;
}

.public-footer .right {
  justify-content: right;
  align-items: center;
  display: flex;
}

.public-footer .right span {
  margin-right: 11px;
}

.public-footer .right svg {
  width: 32px;
  height: 30px;
  flex-shrink: 0;
  color: #fff;
}

.public-footer ul.menu {
  list-style: none;
  margin: 0 10px 0;
  padding: 0;
  display: flex;
}

.public-footer ul.menu li {
  padding: 20px 10px;
  text-transform: uppercase;
  white-space: nowrap;
}


.public-footer .menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-footer .menu-container .separator {
  content: "";
  background-color: #FFF;
  display: inline-block;
  height: 2em;
  width: 2px;
  margin: 10px;
}

.public-footer .menu-text {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 185.714% */
  text-transform: capitalize;
}
.public-footer .menu-text:hover {
  color: var(--color-text-main);
}

.public-header ul.menu li a.active {
  font-weight: bold;
}
