.message-templates-ideas-component .message-card {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  height: 100%;
  box-sizing: border-box;
}

.message-templates-ideas-component .title {
  font-weight: 600;
}

.message-templates-ideas-component .content {
  margin: 0 0 10px 0;
}

