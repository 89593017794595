.preview-message-modal-component {
  font-size: 16px;
}

.preview-message-modal-component audio,
.preview-message-modal-component video {
  width: 100%;
}

.preview-message-modal-component .message-content {
  background: var(--color-info-background);
  padding: 10px;
  border-radius: 10px;
}
