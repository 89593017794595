.container-component {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}


@media (min-width: 576px) {
  .container-component {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container-component {
    width: 720px;
    max-width: 100%
  }
}

@media (min-width: 992px) {
  .container-component {
    width: 960px;
    max-width: 100%
  }
}

@media (min-width: 1200px) {
  .container-component {
    width: 1140px;
    max-width: 100%
  }
}

@media (min-width: 1400px) {
  .container-component {
    width: 1340px;
    max-width: 100%
  }
}
