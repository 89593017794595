.footer {
  margin: 30px 0;
}

.footer .footer-links {
  color: #000;
  font-size: 14px;
}

.footer .footer-links a {
  color: #000;
}

.footer .copyright {
  color: var(--color-text-lightgray);
  font-size: 14px;
}
