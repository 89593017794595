.bubble-hint-component .event-type-info-block {
  background: var(--color-info-background);
  padding: 10px;
}

.bubble-hint-component .event-type-info-block .event-type-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.bubble-hint-component .event-type-info-block .event-type-icon {
  color: var(--color-text-lightgray);
}
