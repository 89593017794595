.message-templates-examples-component .message-card {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
}

.message-templates-examples-component .title {
  font-weight: 600;
}

.message-templates-examples-component .content {
  margin: 0 0 10px 0;
  cursor: pointer;
}

.message-templates-examples-component .preview-message {
  padding: 0;
}
