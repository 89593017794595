.step-message-preview-component form {
  height: 100%;
}

.step-message-preview-component .selected-file {
  background: var(--color-main);
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
}

.step-message-preview-component .selected-file a {
  color: #ccc8f5;
}

.step-message-preview-component .content-area {
  margin-bottom: 40px;
}

.step-message-preview-component video,
.step-message-preview-component audio
{
  width: 100%;
}

.step-message-preview-component .uploading-progress .ant-progress {
  margin-bottom: 4px;
}

.step-message-preview-component .video-overlay {
  margin: 0 auto 10px;
  width: 100%;
}

.step-message-preview-component .audio-overlay {
  margin: 0 auto 10px;
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .step-message-preview-component .video-overlay {
    width: 640px;
    height: 480px;
  }

  .step-message-preview-component .audio-overlay {
    width: 640px;
  }
}
