.account-settings-component .information-block {
  padding: 16px;
  background: var(--color-info-background);
  border-radius: 12px;
  border: 1px solid var(--color-info-border);
  margin-top: 20px;
}

.account-settings-component .avatar-block {
  position: relative;
  max-width: 124px;
  height: auto;
}

.account-settings-component .avatar {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.account-settings-component .remove-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
}

.account-settings-component .information-block h3 {
  margin: 0 0 20px;
}

.account-settings-component .info-field {
  border: 1px solid var(--color-info-input-border);
  padding: 10px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
}

.account-settings-component .info-field .label {
  position: absolute;
  top: -10px;
  left: 8px;
  background: var(--color-info-background);
  color: var(--color-text-lightgray);
  padding: 0 5px;
  font-size: 12px;
}

.account-settings-component .bottom-block {
  margin-top: 10px;
}
