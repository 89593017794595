.pricing-component .payment-plans {
  padding: 30px 0;
}

.pricing-component .payment-plans .payment-plan-item {
  border-radius: 12px;
  border: 1px solid #eaeaea;
  box-shadow: 4px 8px 20px 0 var(--color-text-lightgray);
  padding: 10px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.pricing-component .plans-hint {
  font-size: 12px;
  margin-top: 20px;
}

.pricing-component .payment-plans .payment-plan-item .plan-block {
  height: 100%;
}

.pricing-component .payment-plans .payment-plan-item .title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: var(--color-text-secondary);
}

.pricing-component .payment-plans .payment-plan-item .price-block {
  text-align: center;
  padding: 12px 0;
}

.pricing-component .payment-plans .payment-plan-item .credits-count {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  color: var(--color-logo-main-bg);
  line-height: 1;
}

.pricing-component .payment-plans .payment-plan-item .price {
  font-weight: 900;
  font-size: 36px;
  margin: 10px 0;
}

.pricing-component .payment-plans .payment-plan-item .price span {
  font-size: 16px;
  font-weight: initial;
  color: var(--color-text-lightgray);
}


.pricing-component .payment-plans .payment-plan-item .button-block {
  text-align: center;
}

.pricing-component .payment-plans .payment-plan-item .button-block .selected {
  font-size: 16px;
  padding: 4px 0;
  font-weight: bold;
  color: var(--color-main);
}

.pricing-component .payment-plans .payment-plan-item.popular {
  background: var(--color-main);
  border-color: var(--color-main);
  color: #fff;
}

.pricing-component .payment-plans .payment-plan-item.popular .title {
  border-bottom-color: rgba(234, 234, 234, 0.24);
}

.pricing-component .payment-plans .payment-plan-item.popular .credits-count {
  color: #fff;
}

.pricing-component .payment-plans .payment-plan-item.popular .label-text {
  position: absolute;
  top: 24px;
  right: -28px;
  background: #D225A1;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  padding: 4px 24px;
  transform: rotate(45deg);
}

.pricing-component .payment-plans .payment-plan-item.popular .ant-btn {
  font-weight: 700;
}

.pricing-component .got-credits {
  font-size: 16px;
  margin: 30px 0 40px;
}

.checkout-form-component {
  margin-top: 30px;
}

.pricing-component .contact-us {
  margin-top: 15px;
}

.pricing-component .contact-us a {
  font-weight: 600;
  text-decoration: underline;
}

.pricing-component .payment-plans .payment-plan-item .highlights ul {
  list-style-type: none;
  padding-left: 5px;
  margin: 10px 0 30px;
}
.pricing-component .payment-plans .payment-plan-item .highlights ul li {
  margin: 10px 0;
}

.pricing-component .payment-plans .payment-plan-item .highlights ul li.disabled {
  color: var(--color-text-lightgray);
}

.pricing-component .payment-plans .payment-plan-item .highlights ul li .tick-icon {
  color: var(--color-main);
}

@media (min-width: 992px) {
  .pricing-component .payment-plans .payment-plan-item .extra.empty {
    display: block;
  }
}
