.step-recipient-component .add-another-contact {
  margin-top: 50px;
  text-align: center;
}

.step-recipient-component .contact .icon {
  color: var(--color-text-lightgray);
  cursor: pointer;
}

.step-recipient-component .contact:hover .icon {
  color: red;
}

.step-recipient-component .big-button {
  border: 1px solid var(--color-main) ;
  cursor: pointer;
  border-radius: 16px;
  padding: 20px 40px;
  text-align: center;
  color: var(--color-main);
  font-size: 20px;
  font-weight: 600;
}

