.step-message-options-component .sub-action {
  text-align: center;
}

.step-message-options-component .sub-action .sub-button {
  text-transform: uppercase;
  font-size: 14px;
}

.step-message-options-component .sub-text {
  margin-bottom: 10px;
  font-weight: 600;
}

.step-message-options-component .error {
  margin-bottom: 10px;
}

.step-message-options-component .access-camera {
  text-align: left;
}

.step-message-options-component .ant-upload-wrapper {
  display: flex;
}
