.social-login .social-login-button {
  display: flex;
  margin: 0 auto;
  background: #2473F2;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  width: 250px;
  max-width: 100%;
  justify-content: center;
}

.social-login .social-login-button svg, .social-login .social-login-button img {
  margin-right: 10px;
  font-size: 20px;
}

.social-login .social-login-button.google {
  background: #FFF;
  color: #000;
  border: 1px solid #d1d6db;
}
