.billing-component .current-plan {
  margin: 50px 0;
}

.billing-component .billing-info {
  margin: 50px 0;
}

.billing-component .card-block {
  margin-bottom: 20px;
}
