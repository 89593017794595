.responsive-table .ant-space {
  width: 100%;
}

.responsive-table.mobile .ant-table-tbody >tr >td {
  border-bottom: 0;
}
.responsive-table.mobile .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
  background: transparent;
}

.responsive-table-card {
  color: var(--color-text-secondary);
  background: var(--color-info-background);
  border-radius: 10px;
  padding: 16px;
  margin: -11px -16px;
}

.ant-table-small .responsive-table-card {
  padding: 8px;
  margin: -4px -8px;
}
.ant-table-middle .responsive-table-card {
  padding: 12px 8px;
  margin: -8px;
}

.responsive-table-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-text-main);
}

.responsive-table-subtitle span {
  font-weight: 500;
}

.responsive-table-card .ant-tag {
  margin-inline-end: 0;
}
