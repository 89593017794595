.gift-checkout .product-info {
  background: var(--color-info-border);
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 20px;
}

.gift-checkout .product-info .total {
  font-weight: 600;
}

.gift-checkout .coupon-label {
  margin-bottom: 20px;
  margin-right: 24px;
}

.gift-checkout .ant-form-item {
  margin-bottom: 20px;
}

.gift-checkout .your-coupon {
  margin-bottom: 24px;
}

.gift-checkout .pay-button {
  margin-top: 20px;
}

.gift-checkout .error-message {
  margin-bottom: 10px;
}
