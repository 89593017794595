.select-create-message-component {
  margin: 50px 0;
}

.select-create-message-component .title {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  margin: 0 0 30px;
}

.select-create-message-component .subtitle {
  margin: 0 0 30px;
  font-size: 20px;
}

.select-create-message-component .card {
  border: 1px solid var(--color-main) ;
  cursor: pointer;
  border-radius: 16px;
  padding: 30px;
  text-align: center;
}

.select-create-message-component .card .title {
  font-size: 18px;
  font-weight: 600;
}

.select-create-message-component .image {
  margin-bottom: 20px;
}
