.sysadmin-preview-message-modal-component {
  font-size: 16px;
}

.sysadmin-preview-message-modal-component audio,
.sysadmin-preview-message-modal-component video {
  width: 100%;
}

.sysadmin-preview-message-modal-component .message-content {
  background: var(--color-info-background);
  padding: 10px;
  border-radius: 10px;
}

.sysadmin-preview-message-modal-component .contact-content {
  background: var(--color-info-background);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}
