:root {
  --font-family-title: 'Kodchasan', sans-serif;
  --font-family-text: 'Montserrat', sans-serif;
  --color-text-main: #312e4f;
  --color-text-secondary: #5d5a81;
  --color-text-secondary2: #2b265c;
  --color-text-lightgray: #aeacc0;
  --color-main: #5449dc;
  --color-main-background: #eeedfb;
  --color-btn-background: #e0ddff;
  --color-info-background: #f6f6fd;
  --color-info-border: #f1f0fc;
  --color-info-input-border: #dddbf8;
  --color-logo-main-bg: #3c30c9;
  --color-progress-bar: #f1f0fc;
  --color-progress-bar-active: #a9a4ed;
}

body {
  font-family: var(--font-family-text);
}

.layout-full-height {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-main-content {
  flex: 1;
}

.layout-main-content-flex {
  flex: 1;
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-title);
  text-transform: uppercase;
}

strong {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.ant-modal {
  width: 700px !important;
}
.ant-modal.size-sm {
  width: 500px !important;
}
.ant-modal.size-lg {
  width: 900px !important;
}

@media (max-width: 576px) {
  .ant-alert {
    flex-wrap: wrap;
  }

  .ant-alert-action {
    display: flex;
    flex-basis: 100%;
    margin-top: 10px;
    margin-left: 34px;
  }
}


/* Animation */

.box-shadow-animation {
  transition: box-shadow .2s cubic-bezier(0, 0, 0.2, 1);
}
.box-shadow-animation:hover {
  box-shadow: -1px -2px 10px 0 var(--color-main);
}

.ant-modal-mask-motion {
  transform: scaleY(.01) scaleX(0);
  animation: unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.ant-modal-motion-appear {
  transform-origin: center !important;
  transform: scale(0);
  animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.ant-modal-motion-leave {
  transform: scale(0);
  animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}

@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
}
