.audio-recorder-component audio {
  width: 100%;
}

.audio-recorder-component .microphone {
  font-size: 160px;
  color: var(--color-progress-bar-active);
  margin: 60px;
}

.audio-recorder-component .audio-overlay {
  position: relative;
  display: flex;
  background: var(--color-main-background);
  border-radius: 10px;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  margin: 0 auto 10px;
  width: 100%;

}

.audio-recorder-component .audio-overlay .recording-recorder-component {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background: #aaaa;
  padding: 8px 10px;
  border-radius: 10px;
}

.audio-recorder-component .audio-buttons img {
  width: 50px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .audio-recorder-component .audio-overlay {
    width: 640px;
    height: 480px;
  }
}
