.message-templates-component .filter-block {
  font-size: 14px;
  background: var(--color-info-background);
  border-radius: 10px;
  padding: 10px 20px 20px;
  margin: 20px 0;
}


.message-templates-component .ant-tabs-nav {
  margin: 0 10px;
}

.message-templates-component .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  text-transform: uppercase;
  background: #fff;
}

.message-templates-component .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--color-info-background);
}

.message-templates-component .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-main);
}

.message-templates-component .ant-tabs-content-holder {
  background: var(--color-info-background);
  border-radius: 10px;
  padding: 20px;
}

.message-templates-component .filter-select {
  width: 200px;
}

.message-templates-component .filter-block .filter-description {
  margin-bottom: 20px;
}

.message-templates-component .filter-block .ant-select-selector {
  background: var(--color-info-background);
}

.message-templates-component .info-field {
  position: relative;
}

.message-templates-component .info-field .label {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  top: -7px;
  left: 7px;
  background: var(--color-info-background);
  color: var(--color-text-secondary);
  padding: 0 5px;
}
