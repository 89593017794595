.contact-us-component {
  margin: 20px 0;
}

.contact-us-component h3 {
  text-transform: initial;
}

.contact-us-component .image {
  text-align: right;
}

.contact-us-component .image img {
  width: 100%;
}

