.video-recorder-component .video-overlay {
  position: relative;
  display: flex;
  margin: 0 auto 10px;
  width: 100%;
}

.video-recorder-component .video-overlay .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000050;
  display: flex;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video-recorder-component .video-overlay .recording-recorder-component {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background: #aaaa;
  padding: 8px 10px;
  border-radius: 10px;
}

.video-recorder-component .video-overlay .countdown-number {
  font-size: 82px;
  font-weight: 700;
}

.video-recorder-component .video-overlay .icon {
  font-size: 80px;
}

.video-recorder-component .video-buttons img {
  width: 50px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .video-recorder-component .video-overlay {
    width: 640px;
    height: 480px;
  }
}
