.signup .agree-block {
  text-align: left;
}

.signup .agree-block .error {
  color: red;
}

.signup .ant-form-item .ant-form-item {
  margin-bottom: 0;
}

.signup .error {
  text-align: left;
  margin-top: 5px;
  color: #ff4d4f;
}

