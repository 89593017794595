.step-review-component .block-list {
  width: 100%;
}

.step-review-component .block .title {
  font-size: 18px;
  margin-bottom: 10px;
}

.step-review-component .block .content {
  margin-left: 20px;
}

.step-review-component .block .message-file {
  display: flex;
  margin-top: 10px;
}

.step-review-component .selected-file {
  background: var(--color-main);
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
}

.step-review-component .message-content {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

.step-review-component .message-content .message-content-overlay {
  background: linear-gradient(to top, rgba(255,255,255,1) 0, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 50%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.step-review-component .message-content.open {
  max-height: initial;
}

.step-review-component .message-content.open .message-content-overlay {
  display: none;
}
